import React, { useContext, useEffect, useMemo } from 'react';
import { sortedAttributes } from '.';
import SimpleBar from 'simplebar-react';
import { forecastTimeIntervals, forecastTimePill } from '../legends/colorScale';
import { ForecastContext } from '../Forecasts';
import { Toast } from '../Toast';
import LayerConfigs from '../ol/LayersContext';
import axios from 'axios';
import I18n from "../../packs/translations"
import useCurrentUser from '../Users/CurrentUser';

const FeatureTable = ({ feature }) => {
  const { currentForecast, loadForecast } = useContext(ForecastContext)
  const [currentFeature, setCurrentFeature] = React.useState(feature);
  const layerConfigs = useContext(LayerConfigs);
  I18n.t('components.feature_info.sidebar.no_attributes')

  // differenciate if delay is either null or 0
  const delay = currentFeature.properties?.dysfunctionment.delay
  const dysfunctionsIn = delay ? delay / 60 / 60 : delay;
  const currentUser = useCurrentUser()

  const interval = forecastTimeIntervals({ currentForecast, delay: dysfunctionsIn })

  const isDysfunctional = (delay) => delay === 0

  const featureLayer = useMemo(() => {
    return layerConfigs.layers.find((l) => l.id === currentFeature.layerId)
  }, [currentFeature.layerId, layerConfigs.layers])

  useEffect(() => {
    const { ogcFid } = feature.properties;
    axios.get(`/layers/${featureLayer.uuid}/features_by_ids.json`,
      { params: { featureIds: [ogcFid], forecastBulletinId: currentForecast.id } })
      .then(({ data: featuresData }) => {
        const updatedFeature = featuresData[0];
        setCurrentFeature({ ...updatedFeature, layerId: featureLayer.id });

      })
  }, [currentForecast.id, feature.properties.ogcFid, featureLayer.uuid, featureLayer.id])

  const changeFeatureStatus = (e) => {
    e.stopPropagation()
    const { layerId } = currentFeature
    const { ogcFid, dysfunctionment } = currentFeature.properties
    const isFunctional = isDysfunctional(dysfunctionment?.delay)
    const url = `/layers/${layerId}/features/${ogcFid}/status`
    axios.put(url, { forecast_bulletin_id: currentForecast.id, is_functional: isFunctional })
      .then(() => {
        Toast.success({ title: 'Mise à jour réussie', text: `Statut changé pour ${isFunctional ? 'functionel' : 'dysfunctionel'}` })
        if (currentFeature.properties.dynamicProperties.length === 0) {
          currentFeature.properties.dynamicProperties = [{ featureResultOverrideId: true }]
        } else {
          currentFeature.properties.dynamicProperties[0].featureResultOverrideId = true
        }
        setCurrentFeature({
          ...currentFeature, properties: {
            ...currentFeature.properties,
            dysfunctionment: { delay: isFunctional ? null : 0 },
            dynamicProperties: { ...currentFeature.properties.dynamicProperties }
          }
        })

        return loadForecast(currentForecast.id)
      })
      .catch((error) => {
        console.error(error)
        Toast.error({ title: 'Impossible de mettre à jour le statut', text: error })
      })
    return false
  }

  const removeOverride = (e) => {
    e.stopPropagation()
    const { layerId } = currentFeature
    const { ogcFid } = currentFeature.properties
    const url = `/layers/${layerId}/features/${ogcFid}/status`
    axios.delete(url, { data: { forecast_bulletin_id: currentForecast.id } })
      .then(() => {
        Toast.success({ title: 'Mise à jour réussie', text: `Statut changé pour fonctionel` })
        currentFeature.properties.dynamicProperties[0].featureResultOverrideId = false
        setCurrentFeature(feature)

        return loadForecast(currentForecast.id)
      })
      .catch((error) => {
        Toast.error({ title: 'Impossible de mettre à jour le statut', text: error })
      })
    return false
  }


  if (!interval) {
    Toast.error({ title: 'No forecast interval found for this feature' })
    console.error({ currentFeature, attributes, dysfunctionsIn, interval })
    return <div className="alert alert-danger">No forecast interval found for this feature</div>
  }
  const attributes = [...feature.properties.dynamicProperties, ...feature.properties.publicValues]

  return !attributes?.length
    ? <div className="alert-inpage alert-warning">
      {I18n.t('components.feature_info.sidebar.no_attributes')}
      {currentUser.isAdmin && <>
        <br />
        <a href={`/layers/${currentFeature.layerId}/feature_public_displays`} target='_blank' rel='noopener noreferrer'>
          {I18n.t('components.feature_info.sidebar.setup_public_attributes')}
        </a>
      </>}
    </div>
    : <table className="table table-sm table-striped table-dark">
      <tbody>
        {currentFeature.properties?.dynamicProperties.length !== 0 && <tr>
          <th>Dysfonctionne</th>
          <td>
            {forecastTimePill(interval)}
            {currentFeature.properties?.dynamicProperties?.[0]?.featureResultOverrideId
              ? <>
                <i className="fas fa-star align-top" style={{ fontSize: '10px', color: '#FFFF49' }} title="Statut surchargé"></i>
                <a href="#" onClick={removeOverride} className=" pull-right mx-1" title="Changer le statut">
                  <i className="fas fa-ban"></i>
                </a>
              </>
              : <a href='#' className="pull-right mx-1" onClick={changeFeatureStatus}>{
                isDysfunctional(currentFeature.properties?.dysfunctionment?.delay)
                  ? <i className="fas fa-check text-success" title='Marquer comme fonctionnel'></i>
                  : <i className="fas fa-times text-danger" title='Marquer comme dysfonctionnel'></i>
              }</a>
            }

          </td>
        </tr>
        }
        {sortedAttributes(attributes, currentFeature)
          .filter(({ name }) => name)
          .map(({ name, value }) => (
            <tr key={`${name}-${value}`}>
              <th>{name}</th>
              <td>
                <span dangerouslySetInnerHTML={{ __html: value }} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
};

const PopupFeatureInfo = ({ content }) => (
  <div id="sidebar-feature-info" className={content.length ? 'visible' : undefined}>
    <SimpleBar style={{ maxHeight: '100vh' }}>
      {content
        .map(({ layerName, features }) => (
          <div
            key={`features-${layerName}`}
            className="card bg-dark text-white"
          >
            <div className="card-header">{layerName}</div>
            <div
              className="card-body table-responsive"
              data-simplebar="init"
            >
              {features.map((feature) => (
                <FeatureTable key={`FeatureTableFeature-${feature?.properties?.ogcFid}`}
                  feature={feature}
                />
              ))}
            </div>
          </div>
        ))}
    </SimpleBar>
  </div>
);

export default PopupFeatureInfo;
