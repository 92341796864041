import React from 'react'
import ForecastContext from './Context'
import moment from 'moment'

interface AlternativeBadgeProps {
  bulletin: any,
  size?: string,
  emptyValue?: string,
  colorStyle?: string,
  showDate?: boolean,
  style?: object,
  children?: React.ReactNode,
}

const AlternativeBadge = ({ bulletin, size, emptyValue = '', colorStyle = 'secondary', showDate = false, style={}, children, ...other }: AlternativeBadgeProps): JSX.Element => {
  const badge = bulletin.forecastAlternative ?
    <span className={`badge badge-pill badge-${colorStyle}`} style={style}>
      {showDate
        ? <>
          {moment(bulletin.issuedAt).utc().format('L HH:mm')}
          <small className='ml-2'>{bulletin.forecastAlternative?.name}</small>
        </>
        : bulletin.forecastAlternative?.name}

      {children}
    </span>
    : null

  switch (size) {
    case 'lg': return <h3 {...other}>{badge}</h3>;
    default: return badge;
  }
}

export {
  ForecastContext,
  AlternativeBadge,
}