/**
 * Return array of the feature's sorted attributes
 * @param {object[]} attributes Feature's attribute configuration
 * @param {object} feature The feature
 */
const sortedAttributes = (attributes, feature) => attributes
  .sort((a, b) => a.order - b.order)
  .reduce((acc, { name, attributeName, value, ...other }) => {
    let attrValue;
    if(attributeName){
      attrValue = typeof feature.properties[attributeName] === 'object'
      ? JSON.stringify(feature.properties[attributeName])
      : feature.properties[attributeName]
    } else {
      attrValue = value
    }
    acc.push({
      name,
      value,
    });
    return acc;
  }, []);

export default sortedAttributes;
export { sortedAttributes };
