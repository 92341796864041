import React, { useState, useContext } from 'react'
import Overlay from 'ol/Overlay';
import { useEffect } from 'react'
import { MapContext } from '../ol/mapContext';
import GeoJSON from 'ol/format/GeoJSON';
import { getCenter } from 'ol/extent';
import ActionPlans from './ActionPlans';
import ActionPlansTable from './ActionPlans/Table';
import Axios from 'axios';
import BottomBar from '../BottomBar'
import useCurrentUser from '../Users/CurrentUser'

const FeatureContextMenu = ({ feature }) => {
  const map = useContext(MapContext);
  const currentUser = useCurrentUser()
  const [overlay, setOverlay] = useState()
  const [actionPlans, setActionPlans] = useState([])

  useEffect(() => {
    if (currentUser.hasAccessTo('action_plans')) {
      Axios.get(`/layers/${feature.layerId}/features/${feature.properties.ogc_fid}/action_plans/applyable.json`)
        .then(res => res.data.length
          ? setActionPlans(res.data)
          : setActionPlans([{ name: 'Plan A', id: 1 }, { name: 'Plan B', id: 2 }]))
    }
  }, [feature.layerId, feature.properties.ogc_fid])

  const setFeatureOverlay = () => {
    const overlay = new Overlay({
      positioning: 'center-left',
      element: document.getElementById('marker'),
      stopEvent: true,
    })
    const f = new GeoJSON().readFeature(feature, {
      featureProjection: map.getView().getProjection()
    });
    overlay.setPosition(getCenter(f.getGeometry().getExtent()))
    setOverlay(overlay)
  }

  const addOverlay = (overlay) => {
    if (overlay) {
      map.addOverlay(overlay);
    }
  }

  const removeOverlay = (overlay) => {
    return overlay && map.removeOverlay(overlay)
  }

  useEffect(() => {
    addOverlay(overlay);
    return () => removeOverlay(overlay);
  }, [overlay])

  useEffect(() => {
    if (!feature) {
      removeOverlay(overlay)
    } else {
      setFeatureOverlay()
    }
  }, [feature])

  return actionPlans.length && feature
    ? <span style={{ height: '100vh' }}>
      <div id="marker" style={{
        width: '400px',
        background: '#343a40',
        color: '#CCC',
        padding: '20px',
        borderRadius: '10px',
        marginLeft: '2em',
      }}>
        <ActionPlans feature={feature} actionPlans={actionPlans} />
      </div>
      <BottomBar sidebarOpened={false}>
        <ActionPlansTable feature={feature} actionPlans={actionPlans} />
      </BottomBar>
    </span>
    : null
}

export default FeatureContextMenu;