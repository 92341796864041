import React from 'react'
import { Modal } from 'react-bootstrap'

const KeyrosModal = ({ title, show = false, onHide, children, footer = <> </>,  ...other }) => {
  return (
    <Modal style={{ zIndex: 10000 }} show={show} onHide={onHide} {...other}>

      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>

      <Modal.Footer>
        {footer}
      </Modal.Footer>
    </Modal>
  )
}

export default KeyrosModal