import store from 'store2';

const layerStore = store.namespace('layers');

const MERGEABLE_KEYS = [
  'isVisible',
  // 'opacity',
];

/**
 * Mets à jour l'état de la couche sauvegardé dans le LocalStore
 * @param {Object} layer La couche avec les nouvelles données
 * @returns {Object} La couhe mise à jour
 */
const updateLayer = (layer) => {
  const savedLayer = getSavedLayer(layer);
  const updatedLayer = mergeLayers(savedLayer, layer);
  layerStore.set(layer.uuid, updatedLayer);
  return updatedLayer;
};

/**
 * Fusionne les données de la couche `layer` dans la couche `oldLayer`
 * @param {Object} oldLayer La couche à mettre à jour 
 * @param {Object} layer La couche dont il faut copier les données
 */
const mergeLayers = (oldLayer = {}, layer) => (
  [
    ...MERGEABLE_KEYS,
    ...Object.keys(layer),
  ].filter((value, index, arr) => arr.indexOf(value) === index)
    .reduce((merged, key) => {
      // TODO: Revoir l'utilité de cette fonction
      // Serait utile pour mettre à jour une sous-clé ...
      // Mais là ça annule une modification
      if (MERGEABLE_KEYS.includes(key)) {
        merged[key] = layer && layer[key]
          ? oldLayer[key]
          : layer[key];
      } else {
        merged[key] = layer[key];
      }
      return merged;
    }, {})
);

/**
 * Retourne la couche telle qu'elle est sauvegardée dans le LocalStorage
 * @param {Object} layer La couche recherchée 
 */
const getSavedLayer = (layer) => {

  const savedLayer = layerStore.get(layer.uuid);
  if (savedLayer && savedLayer.uuid !== layer.uuid) {
    layerStore.remove(layer.uuid);
    return undefined;
  }
  return savedLayer;
};

/**
 * Retourne les couches telles qu'elles sont enregistrées dans le LocalStorage
 * @param {Object} layers Les couhes dont on veut obtenir l'état dans le LocalStorage 
 */
const getSavedLayers = (layers) => layers.map(getSavedLayer);

/**
* Recherche la couche telle qu'elle est dans la carte OpenLayers
* @param {Object} map La carte OpenLayers concernée 
* @param {Object} layer La couche recherchée
*/
const getMapLayer = (map, layer) => map.getLayers().getArray().find((l) => (
  l.get('uuid') === layer.uuid
));

/**
 * Écoute les changements aux attributs d'une couche et les 
 * enregistre dans le LocalStorage
 * @param {ol.Layer} layer 
 */
const addCallbacksToLayer = (layer) => {
  layer.on(['change:visible', 'change:opacity'], (e) => {
    const { visible, opacity, name } = e.target.getProperties();
    updateLayer({ uuid: layer.get('uuid'), visible, opacity })
  })
}

const restoreLayerAttributes = (olLayer) => {
  const savedLayer = getSavedLayer({ uuid: olLayer.get('uuid') });
  if (!savedLayer) { return; }
  ['opacity', 'visible'].forEach(key => {
    if (typeof savedLayer[key] !== 'undefined') { olLayer.set(key, savedLayer[key], true); }
  })
}

const getFeatureHighlightLayer = (map) => getMapLayer(map, { id: 'highlight-layer', })

export {
  //updateLayer,
  //updateLayers,
  restoreLayerAttributes,
  getFeatureHighlightLayer,
  getSavedLayer,
  getSavedLayers,
  getMapLayer,
  addCallbacksToLayer,
};
