import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import LayerMenu from "./LayerMenu";
import useCurrentUser from "./Users/CurrentUser";
import axios from "axios";
import KeyrosModal from "./Modal";
import { Button } from "react-bootstrap";
import { sanitize } from "dompurify";
import parse from "html-react-parser";
const Logo = require("./logo.png");
const Logo2 = require("./logo2.png");
// import 'assets/css/animate.css'

const MapSidebar = ({ layers, layerAggregations, mapDisplayed = true }) => {
  const currentUser = useCurrentUser();
  const csrfToken = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [publicPluginRoutes, setPublicPluginRoutes] = useState([]);
  const [privatePluginRoutes, setPrivatePluginRoutes] = useState([]);

  const showSidebar = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    document.body.classList.remove("open");
  };
  const hideSidebar = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    document.body.classList.add("open");
  };

  const closeSession = () => {
    axios.delete("/users/sign_out").catch(({ response }) => {
      // Devise tried to redirect to localhost
      if (response.status === 404) {
        window.location.href = "/users/sign_in";
      } else {
        console.error(response);
      }
    });
  };

  const fetchPluginsRoutes = () => {
    axios.get("/plugins/public_routes.json").then(({ data }) => {
      setPublicPluginRoutes(data);
    })
      .catch((error) => {
        console.error(error);
        setPublicPluginRoutes([]);
      });
  };

  const fetchPrivatePluginsRoutes = () => {
    axios.get("/plugins/private_routes.json").then(({ data }) => {
      setPrivatePluginRoutes(data);
    })
      .catch((error) => {
        console.error(error);
        setPrivatePluginRoutes([]);
      });
  };

  // Prevent dropdown from closing when there is a click inside itself
  useEffect(() => {
    fetchPluginsRoutes();

    fetchPrivatePluginsRoutes();


    jQuery(".dropdown").on("hide.bs.dropdown", function (e) {
      var target = jQuery(e.target);
      if (target.hasClass("keep-open") || target.parents(".keep-open").length) {
        return false; // returning false should stop the dropdown from hiding.
      } else {
        return true;
      }
    });
  }, []);

  /**
   * 
   * @param {Object} action The rails action
   * @returns {string} The url to the action
   */
  const actionLink = ({ controller, action }) => {
    const path = `/${controller.split("/")[1]}/${action}`
    const url = new URL(path, window.location.origin);
    return url.href;
  }

  const isMapDisplayed = () => (
    window.location.pathname === '/maps/1'
  )

  /**
   * Check if the current route starts with the given route
   * 
   * @param {string} route the route to check
   * @returns {boolean} true if the current route starts with the given route
   */
  const isCurrentRoute = (route) => {
    const currentPath = window.location.pathname;
    return currentPath.startsWith(route);
  }

  return (
    <aside
      id={mapDisplayed ? "left-panel" : ""}
      data-simplebar
      className={mapDisplayed ? "left-panel" : ""}
      onMouseEnter={showSidebar}
    >
      <SimpleBar style={{ minWidth: "20v" }}>
        <nav className="navbar navbar-expand-sm navbar-default">
          <div className="navbar-header">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#main-menu"
              aria-controls="main-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars" />
            </button>
            <a
              id="sidebar-closer"
              onClick={hideSidebar}
              href="#"
              className="pull-right p-4"
              style={{
                marginLeft: '10em',
                zIndex: 300,
                position: 'absolute',
              }}
            >
              <i className="fas fa-angle-double-left" />
            </a>
            <div className="navbar-brand row text-center">
              <a className="col-12" href="https://keyros.org" target="_blank">
                <img src={Logo} />
                {window.clientLogo && (
                  <img src={window.clientLogo} height="30" />
                )}
              </a>
            </div>
            <a className="navbar-brand hidden" href="#" onClick={hideSidebar}>
              <img src={Logo2} />
            </a>
          </div>

          <div id="main-menu" className="main-menu collapse navbar-collapse">
            <ul className="nav navbar-nav">
              <h3 className="menu-title">

                <i className='menu-icon fas fa-map'></i>
                <a href="/maps/default" data-turbo="false" >
                  {window.location.pathname === "/maps/default"
                    ? <span style={{ color: "white" }}> Carte </span>
                    : <span> Carte </span>
                  }
                </a>
              </h3>

              <LayerMenu
                layers={layers}
                layerAggregations={layerAggregations}
              />

              <li style={{ borderBottomColor: "#3e3e42", borderBottomWidth: '1px', borderBottomStyle: 'solid' }}></li>

              {publicPluginRoutes.map(
                ({ caption, action, icon, roleNeeded }) =>
                  currentUser.hasRole(roleNeeded) && (
                    <li key={JSON.stringify(action)}>
                      {/* deepcode ignore DOMXSS: Links come from Projections */}
                      <a href={actionLink(action)}>
                        {parse(sanitize(icon, {
                          USE_PROFILES: { html: true },
                        })) || (
                            <i className="fas fa-power-off"></i>
                          )} {caption}
                      </a>
                    </li>
                  )
              )}

              {currentUser.hasRole("admin") && (
                <>
                  <h3 className="menu-title">
                    <i className="fas fa-cog" />
                    Configuration
                  </h3>

                  <li className={isCurrentRoute('/maps') && !isMapDisplayed() ? 'active' : undefined}>
                    <a href="/maps"><i className='menu-icon fas fa-map'></i>
                      <span> Cartes </span>
                    </a>
                  </li>

                  <li className={isCurrentRoute('/layers') ? 'active' : undefined}>
                    <a href="/layers"> <i className='menu-icon fas fa-layer-group'></i>
                      <span> Couches cartographiques </span>
                    </a>
                  </li>
                  <li className={isCurrentRoute('/forecastable_models') ? 'active' : undefined}>
                    <a href="/forecastable_models"> <i className='menu-icon fas fa-chart-line'></i>Prévisions</a>
                  </li>
                  <li className={isCurrentRoute('/events') ? 'active' : undefined}>
                    <a href="/events"> <i className='menu-icon fas fa-clock'></i>Activité</a>
                  </li>

                  <li style={{ borderBottomColor: "#3e3e42", borderBottomWidth: '1px', borderBottomStyle: 'solid' }}></li>

                  {privatePluginRoutes.map(
                    ({ caption, action, icon }) =>
                      <li key={JSON.stringify(action)} className={isCurrentRoute(action.controller) ? 'active' : undefined}>
                        {/* deepcode ignore DOMXSS: Links come from Projections */}
                        <a href={actionLink(action)}>
                          {parse(sanitize(icon, {
                            USE_PROFILES: { html: true },
                          })) || (
                              <i className="fas fa-power-off"></i>
                            )} {caption}
                        </a>
                      </li>
                  )}
                </>
              )}

              <h3 className="menu-title">
                <i className="fas fa-user" />
                Mon compte
              </h3>
              <li>
                <a className="nav-link" href="/users/me">
                  <i className="menu-icon fas fa-user"></i>
                  {window.location.pathname === "/users/me"
                    ? <span style={{ color: "white" }}> Profil </span>
                    : <span> Profil </span>
                  }
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setLogoutModalOpen(true);
                  }}
                >
                  <i className="menu-icon fas fa-power-off"></i> Se déconnecter
                </a>
              </li>
              <li>
                <a className="nav-link" href="/documentation">
                  <i className="menu-icon fas fa-book"></i>
                  {window.location.pathname === "/documentation"
                    ? <span style={{ color: "white" }}> Documentation </span>
                    : <span> Documentation </span>
                  }
                </a>
              </li>
              {currentUser.hasRole("admin") && (
                <>
                  <h3 className="menu-title">Administration</h3>

                  <li className="<%= 'active' if controller.controller_name == 'users' %>">
                    <a href="/users"><i className='menu-icon fas fa-users'></i>
                      {window.location.pathname === "/users"
                        ? <span style={{ color: "white" }}> Utilisateurs </span>
                        : <span> Utilisateurs </span>
                      } </a>
                  </li>
                </>
              )
              }

              <KeyrosModal
                title="Déconnexion"
                show={isLogoutModalOpen}
                children={
                  <p> Êtes vous sur de vouloir vous déconnecter ?</p>
                }
                footer={
                  <>
                    <Button onClick={() => closeSession()}> Oui </Button>
                    <Button onClick={() => setLogoutModalOpen(false)}>
                      Annuler
                    </Button>
                  </>
                }
              />
            </ul>
          </div>
        </nav>
        <div className="text-secondary version text-center text-secondary">
          Version: {window.projectionsVersion}-{window.projectionsBuildNumber} ({window.projectionsVersionDate})
        </div>
      </SimpleBar>
    </aside>
  );
};

MapSidebar.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.shape()),
  layerAggregations: PropTypes.arrayOf(PropTypes.shape()),
};

MapSidebar.defaultProps = {
  layers: [],
  layerAggregations: [],
};

export default MapSidebar;
