import React, { useEffect, useContext, useState } from 'react';
import Vector from 'ol/layer/Vector';
import PropTypes from 'prop-types';
import { MapContext } from '../mapContext';
import { addCallbacksToLayer, restoreLayerAttributes } from '../../LayerStore'

const VectorLayer = ({
  source, isVisible: visible, projection, opacity, name, type, uuid, id, ...other
}) => {
  const map = useContext(MapContext);
  const [layerSource, setLayerSource] = useState();

  const addSource = () => {
      const VectorLayer = new Vector({
      id,
      source,
      visible,
      opacity,
      name,
      uuid,
      type,
      projection,
      ...other,
    });
    restoreLayerAttributes(VectorLayer)
    addCallbacksToLayer(VectorLayer)
    setLayerSource(VectorLayer);
  };
  useEffect(() => {
    addSource();
  }, [source]);

  useEffect(() => {
    if (layerSource) {
      map.addLayer(layerSource);
    }

    return () => {
      if (layerSource) {
        map.removeLayer(layerSource);
      }
    }
  }, [layerSource])

  return null;
};

VectorLayer.propTypes = {
  visible: PropTypes.bool,
  opacity: PropTypes.number,
  name: PropTypes.string.isRequired,
  projection: PropTypes.string.isRequired,
};

VectorLayer.defaultProps = {
  visible: true,
  opacity: 1,
  projection: 'EPSG:4326',
};

export default VectorLayer;
