import React, { useState, useMemo, useEffect } from 'react';



const LayersContext = React.createContext({
  layers: [],
  setLayers: () => { console.warn('setLayers not implemented')},
  getLayerByName: () => { console.warn('getLayerByName not implemented') },
  getLayerByUuid: () => { console.warn('getLayerByUuid not implemented') },
  updateLayer: () => { console.warn('updateLayer not implemented') },
});
LayersContext.displayName = 'LayersContext';

/**
 * A provider for the LayersContext
 * @param {ReactNode} children The children to be rendered
 * @param {Object[]} layers The layers to be added to the context
 * @returns {ReactNode} The children wrapped in the context
 */
const LayersProvider = ({ children, layers=[] }) => {
  // We need to keep the layers in state so that we can trigger a re-render
  const [contextLayers, setContextLayers] = useState(layers)

  // The interface for the context
  // We memoize the value so that it is only re-created when the layers change
  const defaultValue = useMemo(() => ({
    layers: contextLayers,
    setLayers: setContextLayers,
    /**
     * Search for a layer in the context by name
     * @param {Object} layer The object representing the layer
     * @returns {Object|undefined} The layer object from the context
     */
    getLayerByName: (layer) => (
      contextLayers.find((l) => l.name === layer.name)
    ),
    /**
     * Search for a layer in the context by UUID
     * @param {Object} layer The object representing the layer
     * @returns {Object|undefined} The layer object from the context
     */
    getLayerByUuid: (layer) => (
      contextLayers.find((l) => l.uuid === layer.get('uuid'))
    ),
    /**
     * Update a layer in the context
     * @param {Layer} olLayer The OpenLayers layer
     * @param {Object} updatedAttributes The attributes to update
     */
    updateLayerByOlLayer: (olLayer, updatedAttributes) => {
      const index = contextLayers.findIndex((l) => l.uuid === olLayer.get('uuid') || l.name === olLayer.get('name') || l.name === olLayer.name);
      if (index >= 0) {
        contextLayers[index] = {...contextLayers[index], ...updatedAttributes, olLayer};
        setContextLayers([...contextLayers]);
      }
    },
    /**
     * Add a layer to the context
     * @param {Object} layer The object representing the layer
     * @returns {undefined}
     * @throws {Error} If the layer already exists in the context
     * @throws {Error} If the layer is missing a name or uuid
     */
    addLayer: (layer) => {
      if (!layer.name) {
        throw new Error('Layer must have a name');
      }
      if (!layer.uuid) {
        throw new Error('Layer must have a uuid');
      }
      if (contextLayers.find((l) => l.uuid === layer.uuid || l.name === layer.name)) {
        throw new Error(`Layer ${layer.name} (${layer.uuid}) already exists`);
      }

      setContextLayers([...contextLayers, layer])
    },
    /**
     * Remove a layer from the context
     * @param {Object} layer The object representing the layer
     * @returns {undefined}
     * @throws {Error} If the layer is missing a name or uuid
     */ 
    removeLayer: (layer) => {
      if (!layer.name && !layer.uuid) {
        console.warn('Layer must have a name or uuid');
        return;
      }
      const index = contextLayers.findIndex((l) => l.uuid === layer.uuid || l.name === layer.name);
      if (index >= 0) {
        contextLayers.splice(index, 1);
        setContextLayers([...contextLayers]);
      }
    }

  }), [contextLayers]);

  // Update contextLayers when layers prop changes
  useEffect(() => {
    setContextLayers(layers)
  }, [layers])

  return (
    <LayersContext.Provider value={{...defaultValue, layers: contextLayers }}>
      {children}
    </LayersContext.Provider>
  );
};

/**
 * Consumer for the LayersContext
 */
const LayerConsumer = LayersContext.Consumer;

/**
 * Hook to use the LayersContext 
 * @returns {Object} The LayersContext
 */
const useLayers = () => {
  const context = React.useContext(LayersContext);
  if (context === undefined) {
    throw new Error('useLayers must be used within a LayersProvider');
  }
  return context;
};

export { LayersProvider, LayerConsumer, LayersContext, useLayers };

export default LayersContext;
