import React from 'react';

const colors = {
  0: '#e43d1c',
  24: '#f29b2a',
  48: '#ffec00',
}

const ActionPlans = ({ feature, actionPlans }) => {
  const startColor = colors[feature.dysfunctionAt] || '#fd7e14';
  const dysfunctionColor = colors[0];
  const fixedColor = '#00fa00';
  const headerStyle = {
    width: "25%", 
    color: '#CCC',
    background: 'transparent',
    borderRight: '1px solid #CCC',
  }
  return <div>
    <div className="row">
      <div className="col-5">
      </div>
      <div className="col-7">
        <div className="progress" style={{
          background: 'transparent'
        }}>
          <div className="progress-bar" role="progressbar" style={headerStyle} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          <div className="progress-bar" role="progressbar" style={headerStyle} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">T+24H</div>
          <div className="progress-bar" role="progressbar" style={headerStyle} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">T+48H</div>
          <div className="progress-bar" role="progressbar" style={headerStyle} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">T+72</div>
        </div>
      </div>
    </div>
    {actionPlans.map(plan => {
      const totalLength = 1000.0 * 60 * 60 * 72 // 72h
      const maxDelay = Math.max(...(plan.effects || []).map(({delay}) => delay)) * 1000
      const minDuration = Math.min(...(plan.effects || []).map(({effectDuration, delay}) => effectDuration+delay)) * 1000 - maxDelay
      return <div key={`plan-${plan.id}`} className="row" >
        <div className="col-5">
          {plan.name}
        </div>
        <div className="col-7">
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: `${maxDelay/totalLength * 100}%`, backgroundColor: startColor }} aria-valuenow={maxDelay} aria-valuemin="0" aria-valuemax="100"></div>
            <div className="progress-bar" role="progressbar" style={{ width: `${minDuration/totalLength * 100}%`, backgroundColor: fixedColor }} aria-valuenow={minDuration} aria-valuemin="0" aria-valuemax="100"></div>
            <div className="progress-bar" role="progressbar" style={{ width: `${(totalLength - minDuration - maxDelay)/totalLength * 100}%`, backgroundColor: dysfunctionColor }} aria-valuenow={totalLength - minDuration - maxDelay} aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
})}
  </div>;
}

export default ActionPlans