import React, { useContext } from 'react';
import { getFeatureHighlightLayer } from '../LayerStore'
import { MapContext } from '../ol/mapContext'
import JSON from '../ol/sources/json';
import { useEffect } from 'react';

const FeatureHighlight = ({features}) => {
  return features.length && null
  ? <>
    <JSON key={`feature-highlight`} id={'feature-highlight'} name="FeatureHighlight" features={features} />
  </>
  : null
}

export default FeatureHighlight;