import React, { useEffect, useState, useContext } from 'react'
import { formatDuration, intervalToDuration } from 'date-fns'
import locale from "date-fns/locale/fr";
import axios from 'axios';

const ActionPlansTable = ({ feature, actionPlans }) => {

  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  axios.defaults.headers.common['X-CSRF-Token'] = csrf;

  const applyPlan = (plan) => {
    axios.post(`/action_plans/${plan._key}/execute.json`, {
      featureId: feature._key,
      plannedAt: new Date(),
    });

  }

  const dysfunctionColor = (delay) => {
    switch (delay) {
      case 0:
        return '#FF140C';
      case 24:
        return '#FF8830';
      case 48:
        return '#FFD133';
      default:
        return '#CCCCCC';
    }
  }

  const displayCost = (plan) => {

    let total
    const signs = []
    switch (plan.name) {
      case 'Contourner':
        total = 1;
        break;
      case 'Interrompre':
        total = 2;
        break;
      case 'Ne rien faire':
        total = 0;
        break;
      case 'Réparer':
        total = 3;
        break;
    }
    for (let i = 0; i < total; i++) {
      signs.push(<i className="fas fa-euro-sign"></i>)
    }
    return <h5 className="text-warning">{signs}</h5>
  }
  const dysfunctionsIn = feature.dysfunctionment.delay / 60 / 60
  let enjeu = {}
  switch (parseInt(feature._key, 10) % 3) {
    case 0:
      enjeu = { label: 'Enjeu critique', className: 'danger' }
      break;
    case 1:
      enjeu = { label: 'Enjeu important', className: 'warning' }
      break;
    case 2:
      enjeu = { label: 'Enjeu modéré', className: 'primary' }
      break;
  }

  return (
    <div id="action-plans-overlay">
      <div className="row">
        <div className='col-3'>
          <h4>
            <span className="badge badge-pill" style={{ backgroundColor: dysfunctionColor(dysfunctionsIn) }}>{dysfunctionsIn}H</span>
          </h4>
        </div>
        <div className='col-6 text-light text-center'>
          <h5>{feature.id}</h5>
        </div>
        <div className='col-3 text-right'>
          <h4><span className={`badge badge-${enjeu.className} mb-1`}>
            {enjeu.label}
          </span></h4>
        </div>
        <div className='col-12'>
          <table className="table table-striped table-sm table-dark text-center">
            <thead className="thead-dark">
              <tr>
                <th></th>
                {actionPlans.map(plan => (
                  <th key={`plan-name-${plan._key}`}>{plan.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Délai</th>
                {actionPlans.map(plan => (
                  <td key={`plan-delay-${plan._key}`}>
                    {formatDuration({
                      ...intervalToDuration({
                        start: new Date(),
                        end: new Date(new Date().getTime() + Math.max((plan.effects || []).map(f => f.delay)) * 1000)
                      }),
                      seconds: 0,
                    }, {
                      locale
                    })}
                  </td>
                ))}
              </tr>
              <tr>
                <th>Coût</th>
                {actionPlans.map(plan => (
                  <td key={`plan-cost-${plan._key}`}>
                    {displayCost(plan)}
                  </td>
                ))}
              </tr>
              <tr>
                <th>Ressouces</th>
                {actionPlans.map(plan => (
                  <td key={`plan-cost-${plan._key}`}>
                    <ul>
                      {(plan.neededResources || []).map(r => (
                        <li style={{ listStyle: 'none' }}>
                          {r.name} - ({r.quantity})
                        </li>))}
                    </ul>
                  </td>
                ))}
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                {actionPlans.map(plan => (
                  <th key={`plan-exec-${plan._key}`}>
                    <a className="btn btn-primary" onClick={() => applyPlan(plan)}>
                      Exécuter
                    </a>
                  </th>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>)
}

export default ActionPlansTable