import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import JSON from "./ol/sources/json";
import OSM from "./ol/sources/osm";
import XYZ from "./ol/sources/xyz";
import WMTS from "./ol/sources/wmts";
import TileWMS from "./ol/sources/tile_wms";
import Container, { MapContext } from "./ol/mapContext";
import Legend from "./legends";
import OnClick from "./ol/onclick";
import Timeline from "./Timeline/TimeLine";
import InformationStrip from "./InformationStrip/InformationStrip";
import FeatureInfoLayer from "./FeatureInfoLayer";
import { getSavedLayer } from "./LayerStore";

import MapHeader from "./MapHeader";
import { LayerConsumer } from "./ol/LayersContext";

const Map = ({ map, name, style, handleable }) => {
  if (!map) {
    return <></>;
  }
  const [displayTimeline, setDisplayTimeline] = useState(false);
  const [displaySearchBar, setDisplaySearchBar] = useState(false);
  const getSavedLayerOrDefault = (layer) => {
    const savedLayer = getSavedLayer(layer);
    return savedLayer ? { ...layer, ...savedLayer } : layer;
  };

  const toOpenLayer = useCallback((layer) => {
    switch (layer.sourceType) {
      case "OSM":
        return <OSM key={layer.uuid} {...layer} />;
      case "XYZ":
        return <XYZ key={layer.uuid} {...layer} />;
      case "WMTS":
        return <WMTS key={layer.uuid} {...layer} />;
      case "TileJSON":
        return <JSON key={layer.uuid} {...layer} />;
      case "TileWMS":
        return (
          <TileWMS
            key={layer.uuid}
            {...layer}
          />
        );
      default:
        console.warn(`Unknown source type: ${layer.sourceType}`);
        return null;
    }
  }, []);

  const layers = useMemo(
    () =>
      map?.layers?.map(getSavedLayerOrDefault)
        .concat(map?.layerAggregations?.map(getSavedLayerOrDefault)),
    [map]
  );


  return (
    <div
      id={`map-${name}`}
      style={{
        height: "100%",
        ...style,
      }}
    >
      <InformationStrip />
      <button
        type="button"
        className="btn searchbar-button"
        onClick={() => {
          setDisplaySearchBar(!displaySearchBar);
        }}
      >
        Rechercher
      </button>
      {displaySearchBar && <MapHeader />}

      <Container
        name={name}
        zoom={map.defaultZoom}
        longitude={map.longitude}
        latitude={map.latitude}
        layerConfigs={layers}
        layerAggregations={map.layerAggregations}
      >
        <LayerConsumer>
          {({ layers }) => (
            <>
              {handleable && <OnClick key="clickHandler" />}
              <Legend key="Legend" />
              {handleable && <FeatureInfoLayer />}
              {[...layers.map((layer) => toOpenLayer(layer)).filter((l) => l)]}
            </>
          )}
        </LayerConsumer>
      </Container>

      {handleable && <Timeline />}
    </div>
  );
};

Map.propTypes = {
  map: PropTypes.shape({
    zoom: PropTypes.number,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    layers: PropTypes.arrayOf(PropTypes.shape({})),
    layerAggregations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  handleable: PropTypes.bool,
};

Map.defaultProps = {
  style: {},
  handleable: true,
};

export default Map;
export { MapContext };
