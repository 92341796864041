import React, { useEffect } from "react";
import { useLayers } from "./ol/LayersContext";
import JSON from "./ol/sources/json";

const FeatureInfoLayer = () => {
  const { addLayer, removeLayer } = useLayers();

  useEffect(() => {
    addLayer({
      id: "featureInfo",
      uuid: "featureInfo",
      name: "featureInfo",
      opacity: 0.5,
      zIndex: 10000,
    });
    return () => {
      removeLayer("featureInfo");
    };
  }, []);

  return <JSON
    key="featureInfo"
    name="featureInfo"
    opacity={0.5}
    zIndex={10000}
  />
}

export default FeatureInfoLayer;