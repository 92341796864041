import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import { FieldWithError } from "../Form/Error";
import Select from "../Form/Select";
import axios from "axios";
import { Toast } from "../Toast";

const EMPTY_FEATURE_PUBLIC_DISPLAYS = {
  attributeName: "",
  columnName: "",
  publicName: "",
  layerType: "Layer", // A quoi ce parametre sert-il ?
  id: ""
};

// Enum for sources of attributes
const AttributeSources = {
  Feature: "feature",
  Prevision: "feature_results",
};


const FeaturePublicDisplaysForm = ({ layerId, editedFeaturePublicDisplay, setEditedFeaturePublicDisplay, onChange }) => {
  const csrfToken = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.defaults.headers.common["Key-Inflection"] = "camel";

  const [selectedAttributSource, setSelectedAttributeSource] = useState(
    AttributeSources.Feature
  );
  const [attributeOptions, setAttributesOptions] = useState([]);

  const attributeOptionsParams = useMemo(() => {
    switch (selectedAttributSource) {
      case AttributeSources.Feature:
        return {
          prefix: "",
          path: [],
        }
      case AttributeSources.Prevision:
        return {
          prefix: "feature_results.details->>",
          path: ["feature_results"],
        }
      default:
        console.error("Unknown attribut source");
        return {
          prefix: "",
          path: [],
        }
    }
  }, [selectedAttributSource]);

  useEffect(() => {
    if (editedFeaturePublicDisplay.id &&
      editedFeaturePublicDisplay.includePath.toString() !== '[]'
      && editedFeaturePublicDisplay.includePath.length > 0
    ) {
      setSelectedAttributeSource(AttributeSources.Prevision);
    } else {
      setSelectedAttributeSource(AttributeSources.Feature);
    }
  }, [editedFeaturePublicDisplay]);


  const getFeatureAttributes = (layerId, callback) => {
    axios
      .get(`/layers/${layerId}/features/definition.json`)
      .then((res) => {
        callback(Object.keys(res.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFeatureResultAttributes = (layerId, callback) => {
    axios
      .get(`/layers/${layerId}/features/result_attributes.json`)
      .then((res) => {
        callback(Object.keys(res.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const selectAttributeOption = () => {
    switch (selectedAttributSource) {
      case AttributeSources.Feature:
        getFeatureAttributes(layerId, (attrs) => setAttributesOptions(attrs));
        break;
      case AttributeSources.Prevision:
        getFeatureResultAttributes(layerId, (attrs) =>
          setAttributesOptions(attrs)
        );
        break;
      default:
        console.error("Unknown attribut source");
    }

  };



  useEffect(() => {
    selectAttributeOption();
  }, [selectedAttributSource]);

  const save = (featurePublicDisplay, { setErrors }) => {
    const httpVerb = featurePublicDisplay.id ? "patch" : "post";
    const path = featurePublicDisplay.id
      ? `/layers/${layerId}/feature_public_displays/${featurePublicDisplay.id}.json`
      : `/layers/${layerId}/feature_public_displays.json`;
    const query = axios[httpVerb](
      path,
      {
        feature_public_display: {
          attribute_name: featurePublicDisplay.attributeName,
          column_name: featurePublicDisplay.columnName,
          include_path: featurePublicDisplay.includePath,
          public_name: featurePublicDisplay.publicName,
          layer_type: featurePublicDisplay.layerType,
          position: featurePublicDisplay.position,
          id: featurePublicDisplay.id,
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return query
      .then(() => {
        return Toast.success({
          title: "Enregistré",
          text: "Le champ a été enregistré avec succès",
        })
      })
      .catch((error) => {
        if (error.response.status === 422 /* validation error code */) {
          Toast.error({ title: "Erreur", text: `Veuillez vérifier le formulaire` });
          setErrors(error.response.data);
        } else {
          Toast.error({ title: "Erreur", text: "Une erreur est survenue" });
        }
      })
      .finally(() => {
        if (onChange) { onChange() }
      });
  };

  const initialValues = useMemo(() => (
    {
      ...EMPTY_FEATURE_PUBLIC_DISPLAYS,
      attributeName: editedFeaturePublicDisplay.attributeName || attributeOptions[0],
      publicName: editedFeaturePublicDisplay.publicName || EMPTY_FEATURE_PUBLIC_DISPLAYS.publicName,
      columnName: editedFeaturePublicDisplay.columnName || (
        attributeOptionsParams.prefix ? `${attributeOptionsParams.prefix}'${attributeOptions[0]}'` : attributeOptions[0]
      ),
      id: editedFeaturePublicDisplay.id || '',
      attributeSource: selectedAttributSource || AttributeSources.Feature,
    }
  ), [editedFeaturePublicDisplay]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={save}
      enableReinitialize
    >
      {({ touched, errors, values, setFieldValue, resetForm, ...other }) => (
        <Form>
          <div className="card">
            <div className="card-header">
              <h2>{editedFeaturePublicDisplay.id ? `Modification du champ "${values.publicName}"` : 'Ajouter un champ à afficher'}</h2>
            </div>

            <Field name="id" type="hidden" />

            <div className="card-body">
              <div className="form-group row">
                <label className="col-form-label col-sm-3 required">
                  Nom publiquement affiché
                </label>
                <div className="col-9">
                  <FieldWithError
                    as="input"
                    name="publicName"
                    className={`form-control`}
                    placeholder="Nom publique de la propriété"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-sm-3 required">
                  Source de l'attribut
                </label>
                <div className="col-sm-9">
                  <Select
                    name="attributeSource"
                    onChange={({ value }) => {
                      setSelectedAttributeSource(value)
                    }}
                    options={Object.keys(AttributeSources).map((k) => ({ value: AttributeSources[k], label: k }))}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-sm-3 required">
                  Nom de l'attribut
                </label>
                <div className="col-sm-9">
                  <Select
                    options={attributeOptions.map((attribute) => ({ value: attribute, label: attribute }))}
                    name="attributeName"
                    className={`form-control`}
                    onChange={({ value }) => {
                      setFieldValue(
                        "columnName",
                        attributeOptionsParams.prefix
                          ? `${attributeOptionsParams.prefix}'${value}'`
                          : value
                      );
                      setFieldValue(
                        "includePath",
                        JSON.stringify(attributeOptionsParams.path)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-9"></div>
            <div className="col-sm-3 text-right">
              <button type="reset" className="btn btn-danger mr-2" onClick={(e) => {
                e.preventDefault()
                resetForm({
                  values: EMPTY_FEATURE_PUBLIC_DISPLAYS
                })
                setEditedFeaturePublicDisplay({})
              }}
                disabled={!Object.keys(touched).length && !editedFeaturePublicDisplay.id}>
                Annuler
              </button>
              <button type="submit" className="btn btn-success">
                Enregistrer
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FeaturePublicDisplaysForm;
