import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from '../layers/tile';
import { ForecastContext } from '../../Forecasts'
import { get as getProjection } from 'ol/proj';
import TileGrid from 'ol/tilegrid/TileGrid';
import { getTopLeft, getWidth } from 'ol/extent';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import LayersContext from '../LayersContext';

/**
 * 
 * @param {string} sourceUrl The layer url
 * @param {string} attributions The layer attributions
 * @param {string} format The layer format
 * @param {string} version The layer version
 * @param {boolean} tiled The layer tiled
 * @param {string} layers The layer layers
 * @param {string} projection The layer projection
 * @param {string} type The layer type
 * @param {string} viewParams The layer viewParams
 * @param {string} layerFilters The layer layerFilters
 * @param {Object} other The other props
 * @returns {React.ReactElement}
 * @example
 * <TileWMSSource
 *  sourceUrl="https://geoservices.ign.fr/geoportail/wms/cadastre"
 * attributions="IGN"
 * format="image/png"
 * version="1.3.0"
 * tiled="true"
 * layers="cadastre"
 * projection="EPSG:3857"
 * type="static"
 * />
 * 
 * @returns {React.ReactElement}
 */
const TileWMSSource = ({
  sourceUrl,
  attributions,
  format,
  version,
  tiled,
  layers,
  projection: proj,
  type,
  viewParams,
  layerFilters,
  updatedAt,
  ...other
}) => {
  const { currentForecast, disabledIntervals, showFunctional } = useContext(ForecastContext)
  const [source, setSource] = useState();
  const { layers: layerConfigs } = useContext(LayersContext);

  /**
   * Format the layer filters to be used in the viewparams
   * @param {Array} layerFilters
   * @returns {String}
   * @example
   * formatLayerFilters({Alfortville: 'R060', Austerlitz: '', Bonneville-sur-Iton: ''})
   * // => Alfortville@R060,Austerlitz@,Bonneville-sur-Iton@
   * 
   */
  const formattedLayerFilters = useMemo(() => {
    if(!layerFilters) return null;
    const filters = [];
    Object.keys(layerFilters).forEach(identifier => {
      const filter = layerFilters[identifier];
      filters.push(`${identifier}@${filter}`);
    })
    return filters.join('\\,').toLowerCase();
  }, [layerFilters])

  useEffect(() => {
    // If the layer is static, we don't need to wait for the forecast to be loaded
    if (currentForecast || type === 'static') {

      // We need to register the Lambert projection to use it in the TileWMS source
      proj4.defs(
        "EPSG:2154",
        "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
      );
      register(proj4);
      const projection = getProjection(proj);
      const projectionExtent = [-700000, 5000000, 700000, 7000000];
      const size = getWidth(projectionExtent) / 256;
      const resolutions = new Array(19);
      const matrixIds = new Array(19);
      for (let z = 0; z < 19; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = z;
      }
      const sqlViewparams = formattedLayerFilters ? [`layer_filter:${formattedLayerFilters}`] : [];

      if (type === 'static') {
        // Cache management for static layers
        sqlViewparams.push(`updated_at:${parseInt((new Date(updatedAt)).getTime() / 1000, 10)}`)
      } else {
        // Set the forecast id and the disabled time intervals in the viewparams if the layer is not static
        if (currentForecast?.id) {
          sqlViewparams.push(`forecast_bulletin_id:${currentForecast.id}`)
        }
        if (disabledIntervals.length) {
          sqlViewparams.push(`disabled_intervals:${JSON.stringify(disabledIntervals).replace(/,/g, '\\,')}`)
        }

        sqlViewparams.push(`show_functional:${showFunctional.toString()}`)

        // This value is used to force the browser to reload the tiles when the forecast is updated
        // It is set to the timestamp of the forecast's evaluationsCompletedAt
        sqlViewparams.push(`evaluationsCompletedAt:${parseInt((new Date(currentForecast.evaluationsCompletedAt)).getTime() / 1000, 10)}`)
      }

      // Format the viewparams
      viewParams.forEach(element => {
        sqlViewparams.push(`${element.name}:${element.value}`)
      });

      const allViewparams = sqlViewparams.join(';')

      // Create the TileGrid
      const tileGrid = new TileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
      })
      // Create the TileWMS source
      setSource(new TileWMS({
        url: sourceUrl,
        serverType: 'geoserver',
        hidpi: true,
        params: {
          FORMAT: format,
          VERSION: version,
          TILED: true,
          LAYERS: layers,
          VIEWPARAMS: allViewparams,
          projection: projection.getCode(),
          matrixSet: 'GoogleMapsCompatible',
          tileGrid,
          wrapX: true,
          exceptions: 'application/vnd.ogc.se_inimage',
        },
      }));
    }

    return () => {
      if (source) {
        const layerConfig = layerConfigs.find(l => l.layers === layers)
        layerConfig.params = { ...source.getParams() }
        source.dispose();
      }
    }
  }, [currentForecast, disabledIntervals, showFunctional, type, viewParams, sourceUrl, format, version, tiled, layers, proj, layerFilters]);

  return <TileLayer source={source} {...other} isWMS zIndex={type === 'static' ? 100 : 1000} />;
};

TileWMSSource.propTypes = {
  sourceUrl: PropTypes.string.isRequired,
  attributions: PropTypes.string,
  format: PropTypes.string,
  version: PropTypes.string,
  tiled: PropTypes.bool,
  layers: PropTypes.string.isRequired,
  projection: PropTypes.string,
  viewParams: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })),
};

TileWMSSource.defaultProps = {
  attributions: '',
  viewParams: [],
  format: 'image/png',
  version: '1.1.1',
  tiled: true,
  projection: 'EPSG:3857',
};

export default TileWMSSource;
