import React from 'react'

const BottomBar = ({ children, sidebarOpened }) => {
  const width = sidebarOpened
    ? 'calc(70vw - 85px)'
    : 'calc(100vw - 85px)'
  return (
  <div id="action-plans" style={{
    width,
    bottom: 0,
    position: 'fixed',
    background: '#272c33',
    opacity: '0.97',
    padding: '5px 10px 0px 10px',
    zIndex: 31,
  }}>
    {children}
  </div>
  )
}

export default BottomBar;