import React, { useState, useContext } from 'react'
import AsyncSelect from 'react-select/async';
import Axios from "axios";
import { MapContext } from "../ol/mapContext";
import GeoJSON from "ol/format/GeoJSON";
import { ForecastContext } from "../Forecasts";
import { Formik, Form } from "formik";
import { highlightFeature } from '../ol/onclick';
const SELECTED_FEATURE_ZOOM_LEVEL = 18

const customStyles = {
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: '600px',
    height: '20px',
    border: '2px solid rgb(39, 44, 51, 1)',
    margin: '0px',
    padding: '0px'
  }),
  menu: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: '600px',
    zIndex: 60,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 20,
    width: 600,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const centerOnFeature = async ({ feature }) => {
  if (!feature) return;
  const geoJSON = new GeoJSON();
  const olFeature = geoJSON.readFeature(feature);
  const geom = olFeature.getGeometry();

  let mapGeom;

  if (feature.properties.isGeocoded) {
    mapGeom = geom.transform("EPSG:4326", map.getView().getProjection())
  } else if (feature) {
    mapGeom = geom.transform(feature.properties.projection, map.getView().getProjection())
  }

  const event = {
    type: 'singleclick',
    coordinate: [mapGeom.flatCoordinates[0], mapGeom.flatCoordinates[1]],
    pixel: map.getPixelFromCoordinate([mapGeom.flatCoordinates[0], mapGeom.flatCoordinates[1]]),
    map: map,
    originalEvent: {
      type: 'click',
      target: map.getTargetElement(),
      clientX: map.getPixelFromCoordinate([mapGeom.flatCoordinates[0], mapGeom.flatCoordinates[1]])[0],
      clientY: map.getPixelFromCoordinate([mapGeom.flatCoordinates[0], mapGeom.flatCoordinates[1]])[1],
    },
  }

  map.dispatchEvent(event)

  map.getView().fit(mapGeom, {
    size: map.getSize(),
    duration: 500,
    maxZoom: SELECTED_FEATURE_ZOOM_LEVEL,
  })
};


const SearchBar = () => {

  const [value, setValue] = useState('');
  const map = useContext(MapContext);
  const { currentForecast, showFunctional, disabledIntervals } = useContext(ForecastContext);


  const featureOption = (geojson, q) => {
    const feature = geojson.properties;
    const r = new RegExp(q, "i");
    const propIndex = Object.values(feature).findIndex((v) => r.test(v));
    const prop = Object.keys(feature)[propIndex];
    const value = feature[prop] ? feature[prop].toString() : feature.label;
    let parts;
    value.replace(r, (found, _index, s) => {
      const split = s.split(found);
      parts = [split[0], found, split[1]];
    });
    const key = feature.Key || feature.ogcFid || feature.id;
    return {
      feature: geojson,
      value: key,
      label: (!feature.isGeocoded) ? ( // id attribute for geocoding api is called id
        <div>
          <b>{prop}:</b> {parts[0]}
          <b>{parts[1]}</b>
          {parts[2]}
        </div>
      ) : (
        <div>
          <b>Adresse:</b> {feature.label}
        </div>
      ),
    };
  };

  const coordinatesOption = (coordinates) => {
    const [y, x] = coordinates;

    return {
      feature: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [x, y],
        },
        properties: {
          isDecimal: true,
        }
      },
      value: coordinates.join(", "),
      label: (
        <div>
          <b>Coordonnées:</b> {y}, {x}
        </div>
      ),
    };
  };

  const searchFeatures = (inputValue) => {
    const re = /^-?1?[0-9]{1,2}\.\d{1,16}, +-?1?[0-9]{1,2}\.\d{1,16}/;

    if (inputValue.length < 3) {
      return [];
    }

    if (re.test(inputValue)) {
      // searchbar expects promise
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([coordinatesOption(inputValue.split(",").map(Number))]);
        }, 1000);
      });
    }

    const visibleLayers = map
      .getLayers()
      .getArray()
      .filter((l) => l.getVisible())
      .map((l) => l.get("id"));
    return Axios.get("/features/search", {
      params: {
        q: inputValue,
        forecastBulletinId: currentForecast.id,
        disabledIntervals,
        showFunctional,
        visibleLayers,
      },
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
      .then(({ data }) => {
        return data.map((feature) => featureOption(feature, inputValue));
      })
      .catch((err) => {
        console.error(err);
      });
  };


  return (
    <Formik
      enableReinitialize
      onSubmit={(e) => e.preventDefault()}
    >
      {() => (
        <Form className="" onSubmit={(e) => { e.preventDefault(); return false }}>

          <AsyncSelect
            name="q"
            //className='form-control form-control-sm'
            onChange={centerOnFeature}
            placeholder="Rechercher ..."
            noOptionsMessage={() => "Aucune entrée correspondante"}
            loadingMessage={() => "Chargement ..."}
            styles={customStyles}
            cacheOptions
            loadOptions={searchFeatures}
            defaultOptions
            autoFocus
            onSubmit={(feature) => centerOnFeature(feature).then(() => setValue(''))}
            value={value}
          // key={`selected_value__${selected}`}
          // value={selected || ''}
          //onInputChange={searchFeatures}
          />
        </Form>
      )}
    </Formik>
  )
}


export { SearchBar, centerOnFeature }