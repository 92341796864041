import React, { useContext, useEffect, useState } from 'react';
import LegendCtrl from 'ol-ext/control/Legend';
import Legend from 'ol-ext/legend/Legend';
import PropTypes from 'prop-types';
import {
  Fill, RegularShape, Stroke, Style,
} from 'ol/style';

import TimeLegend from './time';
import CustomLegend from './custom';
import { MapContext } from '../Map';

const createColoredSquare = (color) => {
  if (typeof color === 'object') {
    return createDetailledColoredSquare(color)
  }
  return new Style({
    image: new RegularShape({
      fill: new Fill({ color }),
      stroke: new Stroke({ color: 'black', width: 0.5 }),
      points: 4,
      radius: 10,
      angle: Math.PI / 4,
    }),
  })
};

const createDetailledColoredSquare = ({ fill, stroke }) => {
  return new Style({
    image: new RegularShape({
      fill: new Fill({ fill }),
      stroke: new Stroke({ color: stroke || 'black', width: 0.5 }),
      points: 4,
      radius: 10,
      angle: Math.PI / 4,
    }),
  })
};


const MapLegend = () => {
  const map = useContext(MapContext);
  const [ legend, setLegend ] = useState(null);
  useEffect(() => {
    if (legend) { map.addControl(legend); }
    return () => map.removeControl(legend);
  }, [legend]);


  useEffect(() => {
    const mapLegend = new Legend({ 
      title: '',
      margin: 5
    });
    const legendCtrl = new LegendCtrl({
      legend: mapLegend,
      collapsed: false
    });
    setLegend(legendCtrl);
  }, [])

  return (
    legend && <>
      
      <TimeLegend mapLegend={legend} />
      <CustomLegend mapLegend={legend}/>
      {/* { displayZipZich && <ZichLegend  />} */}
    </>
  );
};

MapLegend.propTypes = {
  displayZipZich: PropTypes.bool,
  display: PropTypes.oneOf(['vertical', 'inline']),
};

MapLegend.defaultProps = {
  displayZipZich: false,
  display: 'vertical',
};

export default MapLegend;
export {
  createColoredSquare,
};
